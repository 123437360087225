import {
    Box,
    Container,
    CssBaseline,
    ThemeProvider,
    Typography,
    useTheme,
} from "@mui/material";
import React from "react";

function Copyright() {
    return (
        <Typography variant="body2" color="primary">
            {"Copyright © Clemente Carlucci "}

            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const StickyFooter = () => {
    const globalTheme = useTheme();

    return (
        <ThemeProvider theme={globalTheme}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "50vh",
                    textAlign: "center",
                }}
            >
                <CssBaseline />
                <Box
                    component="footer"
                    sx={{
                        py: 3,
                        px: 2,
                        mt: "auto",
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey['lighter']
                                : theme.palette.grey['dark'],
                    }}
                >
                    <Container maxWidth="xl">
                        <Typography variant="body1" color={globalTheme.palette.grey['dark']}>
                            Magazzino Acciona - Amministrazione degli scarichi di magazzino
                        </Typography>
                        <Copyright />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default StickyFooter;