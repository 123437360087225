import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { mainNavBarItems } from "../../constants/navbaritems";
import { useNavigate } from "react-router";
//import AuthService from "../../services/auth.service";
import { Button, Typography, Box } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Outlet } from "react-router-dom";
import "../../index.css"; // Import your global CSS file
import AuthService from "../../services/auth.service";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: "100%",
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Navbar = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(undefined);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [submenuStates, setSubmenuStates] = useState({}); // Use an object to manage submenu states

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Function to toggle the visibility of a submenu
  const toggleSubmenu = (menuId) => {
    setSubmenuStates((prevState) => ({
      ...prevState,
      [menuId]: !prevState[menuId],
    }));
  };

  const navigateToSubmenu = (submenuRoute, parentRoute) => {
    navigate(`${parentRoute}/${submenuRoute}`);
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
      setIsAdmin(user.roles.includes("ROLE_AMMINISTRATORE"));
    } else {
      // Redirect to login if the user is not authenticated
      navigate("/");
    }
  }, [navigate]);

  const logOut = () => {
    AuthService.logout();
    // Redirect to login after logout
    navigate("/");
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Magazzino Acciona - Movimenti di scarico
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            backgroundColor: theme.palette.primary.main, // Set the background color to the primary color
            color: "white", // Set the text color to white
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Divider />
        <List>
          {mainNavBarItems.map((item) => (
            (item.role === "ROLE_AMMINISTRATORE" && isAdmin) ||
					(item.role !== "ROLE_AMMINISTRATORE" && currentUser) ? (
						<div key={item.id}>
              <ListItem
                button
                onClick={() => {
                  if (item.children) {
                    toggleSubmenu(item.id); // Toggle the submenu for this item
                  } else {
                    navigate(item.route);
                  }
                }}
              >
                <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
                {item.children && (
                  <div style={{ marginLeft: "auto" }}>
                    {submenuStates[item.id] ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </div>
                )}
              </ListItem>
              {submenuStates[item.id] && item.children && (
                <List>
                  {item.children.map((subItem) => (
                    <ListItem
                      button
                      key={subItem.id}
                      onClick={() =>
                        navigateToSubmenu(subItem.route, item.route)
                      } // Pass the parent route
                      style={{ paddingLeft: "30px" }}
                    >
                      <ListItemIcon>{subItem.icon}</ListItemIcon>
                      <ListItemText primary={subItem.title} />
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
					) : null
            
          ))}
          {currentUser && (
            <ListItem button onClick={logOut}>
              <ListItemIcon sx={{ color: "white" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          )}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
};
export default Navbar;
