import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Scarichi from "./pages/Scarichi";
import Informazioni from "./pages/Informazioni";
import Login from "./components/Login/Login";
import Utenti from "./pages/Utenti";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/" element={<App />}>
        <Route path="/scarichi" element={<Scarichi />} />
        <Route path="/informazioni" element={<Informazioni />} />
        <Route path="utenti" element={<Utenti />} />
      </Route>
    </Routes>
  </BrowserRouter>
);