import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import {
    Grid,
    Alert,
    Snackbar,
    Card,
    CardContent,
    Typography,
    TextField,
    Stack,
    Button,
    Toolbar,
    AppBar,
    CssBaseline,
    Box,
} from "@mui/material";

import AuthService from "../../services/auth.service";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { useMemo } from "react";
// SweetAlert - 20/06/2024
import Swal from 'sweetalert2'
import StickyFooter from "../Footer/StickyFooter";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Questo campo è obbligatorio!
            </div>
        );
    }
};


const Login = () => {
    let navigate = useNavigate();
    const form = useRef();
    const checkBtn = useRef();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false); // per lo snackbar
    const [messageSb, setMessageSb] = useState(""); // messaggio snackbar
    const [severity, setSeverity] = useState("");

    const theme = createTheme({
        palette: {
            type: "light",
            primary: {
                main: "#ff0000",
                light: "#FF3939",
                dark: "#C60000",
                lighter: "#FF6464",
                darker: "#9B0000",
                contrastText: "#fff",
            },
            secondary: {
                main: "#FF7400",
                light: "#FF9339",
                dark: "#C65A00",
                lighter: "#FFAA64",
                darker: "#9B4600",
                contrastText: "#fff",
            },
            alternate: {
                main: "#CD0074",
                light: "#D02F8A",
                dark: "#9F005A",
                lighter: "#D955A0",
                darker: "#7D0046",
                contrastText: "#fff",
            },
            complementary: {
                main: "#00CC00",
                light: "#2FCF2F",
                dark: "#009E00",
                lighter: "#55D955",
                darker: "#007C00",
                contrastText: "#fff",
            },
            grey: {
                lighter: "#DDDCDC",
                light: "#ACACAC",
                main: "#808080",
                dark: "#4E4E4E",
                darker: "#0A0A0A",
                contrastText: "#000",
            }
        },
        shape: {
            borderRadius: 4,
        },
        spacing: 8,
        props: {
            MuiAppBar: {
                color: "default",
            },
            MuiTooltip: {
                arrow: true,
            },
        },
    });


    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = (e) => {
        e.preventDefault();
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            AuthService.login(username, password).then(
                () => {
                    const user = JSON.parse(localStorage.getItem("user"));
                    if (user && user.roles && user.roles.length > 0) {
                        if (
                            user.roles.includes("ROLE_ESPORTATORE") ||
                            user.roles.includes("ROLE_AMMINISTRATORE")
                        ) {
                            Swal.fire({
                                title: "Successo",
                                text: "Login effettuato con successo!",
                                icon: "success",
                                timer: 1500, // Display for 1.5 seconds
                                showConfirmButton: false
                            }).then(() => {
                                navigate("/scarichi");
                            });
                        } else {
                            Swal.fire({
                                title: "Successo",
                                text: "Login effettuato con successo!",
                                icon: "success",
                                timer: 1500, // Display for 1.5 seconds
                                showConfirmButton: false
                            }).then(() => {
                                window.location.reload();
                            });
                        }
                    } else {
                        Swal.fire({
                            title: "Successo",
                            text: "Login effettuato con successo!",
                            icon: "success",
                            timer: 1500, // Display for 1.5 seconds
                            showConfirmButton: false
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setLoading(false);

                    Swal.fire({
                        title: "Errore",
                        text: "Credenziali non valide.",
                        icon: "error",
                    })
                }
            );
        } else {
            setLoading(false);
        }
    };
    

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };


    return (
        <>
            <ThemeProvider theme={theme}>
                <Box sx={{ display: "flex", width: "100%" }}>
                    <CssBaseline />
                    <AppBar position="fixed" open={open}>
                        <Toolbar>

                            <Typography variant="h6" component="div">
                                Magazzino Acciona - Area riservata
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid container>
                        <Grid item xs={12}>
                            <AppBar position="static" color="primary">
                                <Typography variant="h6" component="div">
                                    Magazzino Acciona - Area riservata
                                </Typography>
                            </AppBar>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: "120px" }}>
                            <Card
                                sx={{
                                    backgroundColor: "white",
                                    color: "black",
                                    position: "inherit",
                                    marginTop: "40px",
                                    marginLeft: "25%",
                                    width: "50%",
                                }}
                            >
                                <CardContent textAlign="center">
                                    <Typography
                                        sx={{ fontSize: 24 }}
                                        color="black"
                                        gutterBottom
                                        textAlign={"center"}
                                    >
                                        Login
                                    </Typography>
                                    <Form onSubmit={handleLogin} ref={form}>
                                        <Stack
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <TextField
                                                id="username"
                                                label="Utente"
                                                value={username}
                                                onChange={onChangeUsername}
                                                validations={[required]}
                                            />
                                            <TextField
                                                id="password"
                                                label="Password"
                                                value={password}
                                                onChange={onChangePassword}
                                                validations={[required]}
                                                type="password"
                                            />
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                sx={{ color: "white" }}
                                            >
                                                Login
                                            </Button>
                                            <CheckButton style={{ display: "none" }} ref={checkBtn} />
                                        </Stack>
                                    </Form>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                </Box>
                <footer style={{ marginTop: "1vh" }}>
                    <StickyFooter />
                </footer>
            </ThemeProvider>
        </>
    )
}

export default Login