import { Box, Container, Typography, Button } from '@mui/material'
import React from 'react'

const Informazioni = () => {
  return (
    <Box sx={{ position: 'relative', overflow: 'hidden', height: '100vh' }}>
      <Container>
        <Typography variant="h2" component="h1" gutterBottom>
          Magazzino Acciona
        </Typography>
        <Typography variant="h5" component="p" gutterBottom>
          Interfaccia di amministrazione degli scarichi di magazzino inviati da app mobile.
        </Typography>
        <br />
        <br />
        <div>
          <Typography variant="h6" component="h2">
            Scarica la App Android
          </Typography>
          <br />
          <a href="http://5.249.157.198/downloads/app-release.apk" download style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary">
              Scarica
            </Button>
          </a>
        </div>
      </Container>
    </Box>
  )
}

export default Informazioni
