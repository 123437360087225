import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Navbar from "./components/Navbar/Navbar";
import StickyFooter from "./components/Footer/StickyFooter";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#ff0000",
      light: "#FF3939",
      dark: "#C60000",
      lighter: "#FF6464",
      darker: "#9B0000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FF7400",
      light: "#FF9339",
      dark: "#C65A00",
      lighter: "#FFAA64",
      darker: "#9B4600",
      contrastText: "#fff",
    },
    alternate: {
      main: "#CD0074",
      light: "#D02F8A",
      dark: "#9F005A",
      lighter: "#D955A0",
      darker: "#7D0046",
      contrastText: "#fff",
    },
    complementary: {
      main: "#00CC00",
      light: "#2FCF2F",
      dark: "#009E00",
      lighter: "#55D955",
      darker: "#007C00",
      contrastText: "#fff",
    },
    grey: {
      lighter: "#DDDCDC",
      light: "#ACACAC",
      main: "#808080",
      dark: "#4E4E4E",
      darker: "#0A0A0A",
      contrastText: "#000",
    }
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
  props: {
    MuiAppBar: {
      color: "default",
    },
    MuiTooltip: {
      arrow: true,
    },
  },
});

function App() {
  const navigate = useNavigate();
  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Navbar sx={{ width: "100%" }}></Navbar>
      </Grid>
      <StickyFooter  sx={{ width: "100%"}} />
    </ThemeProvider>
  );
}

export default App;
